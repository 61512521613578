import { useEffect } from 'react';
import triggerExcludes from '../../../utils/triggerExcludes';
import PropTypes from 'prop-types';
import ESI from '../../ESI';
import { PAGE_LOAD } from '../../../utils/events';
import analyticsFactory from '../../../utils/analyticsFactory';
import layout from '../../../scss/layout.module.scss';

import {
  analyticsReady,
  dispatchImmediateAnalyticsEvent,
  setJLData,
} from '@JohnLewisPartnership/jl-analytics';

import { parseCookies } from '../../../utils/cookies';
function ContentLayout(props) {
  const { query, children, url } = props;

  //Provide defaults as not available during build
  const excludeHeader = query ? query.excludeHeader : 'false';
  const excludeFooter = query ? query.excludeFooter : 'false';

  const footerESI = '/esi/footer';

  useEffect(() => {
    const readyInterval = setInterval(() => {
      if (analyticsReady()) {
        clearInterval(readyInterval);
        const payload = analyticsFactory(url);
        const { shopperId } = parseCookies();

        setJLData({
          url,
          query,
          ...payload,
          ...(shopperId && {
            customer: {
              customerInfo: {
                loginStatus: 'recognised login',
                registrationStatus: 'existing registration',
                signinId: shopperId,
              },
            },
          }),
        });
        dispatchImmediateAnalyticsEvent(PAGE_LOAD, {});
      }
    }, 100);
  }, [url, query]);

  return (
    <>
      {!triggerExcludes(excludeHeader) && (
        <ESI
          id="header"
          src={`/scaffold/header.html?includeCoreJS=false&includePolyfills=false&includeCoreCSS=false`}
        />
      )}
      <main id="main" className={layout.main}>
        {children}
      </main>

      {!triggerExcludes(excludeFooter) && <ESI src={footerESI} id="footer" />}
    </>
  );
}

ContentLayout.propTypes = {
  url: PropTypes.string,
  query: PropTypes.shape({
    excludeHeader: PropTypes.string,
    excludeFooter: PropTypes.string,
  }),
};

export default ContentLayout;
