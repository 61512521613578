import React from 'react';

const ESI = ({ id, src }) => {
  return React.createElement('div', {
    id: id,
    'data-testid': id || 'esi-test-id',
    className: `esi-include-wrapper wrapper-${id}`,
    dangerouslySetInnerHTML: {
      __html: `<esi:include src="${src}" />`, // nosemgrep
    },
  });
};

export default ESI;
