import debug from 'debug';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig() || {};
const { applicationNamespace } = publicRuntimeConfig || {};

const DEBUG_NAMESPACE = ['jlp', applicationNamespace].filter(Boolean).join(':');

const instance = debug(DEBUG_NAMESPACE);

export const createDebugger = (name) => instance.extend(name);
