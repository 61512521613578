const routes = [
  {
    regex: /^\/$/,
    pageName: 'homepage',
    pageType: 'home page',
  },
  {
    regex: /\/\?.*/,
    pageName: 'homepage',
    pageType: 'home page',
  },
];

const getBreadcrumbs = (route, page) => {
  let breadCrumb = route && route.breadCrumb;

  const paths = page.split(':');
  if (paths[0] === '') {
    paths.splice(0);
  }

  breadCrumb = paths.map((value) => ({ value, clickable: 1 }));

  return breadCrumb;
};

const getPageInfo = (pathname) => {
  const route = routes.find((routeObject) => routeObject.regex.test(pathname));

  let newPathname =
    pathname.substr(0, 1) === '/' ? pathname.substr(1) : pathname;
  if (newPathname.indexOf('?') > -1) {
    newPathname = newPathname.substring(0, newPathname.indexOf('?'));
  }
  const newPageName = route ? route.pageName : newPathname.replace(/\//g, ':');
  const newPageType = route ? route.pageType : 'content';
  const newPageInfo = route ? route.pageInfo : {};

  const breadCrumb = getBreadcrumbs(route, newPageName);

  return {
    breadCrumb,
    pageName: `jl:${newPageName}`,
    pageType: newPageType,
    site: window.location.host,
    ...newPageInfo,
  };
};

export default function analyticsFactory(pathname = '', data = {}) {
  const analytics = {};
  const { error, customer, ...pageData } = data;

  if (error) {
    analytics.error = error;
  }
  if (customer) {
    analytics.customer = customer;
  }

  analytics.page = {
    locale: {
      country: 'uk',
      currency: 'gbp',
      language: 'en-gb',
    },
    pageInfo: getPageInfo(pathname),
    ...pageData,
  };

  return analytics;
}
