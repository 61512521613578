import React, { useState, useRef, useEffect, useContext } from 'react';
import {
  analyticsReady,
  dispatchImmediateAnalyticsEvent,
  dispatchAnalyticsEvent,
  setJLData,
  ANALYTICS_JLDATA_UPDATED,
  addCustomSchema,
} from '@JohnLewisPartnership/jl-analytics';

import { createDebugger } from '../../utils/debug';

const debug = createDebugger('analytics');

export const Context = React.createContext();

export { addCustomSchema };

export const useAnalytics = (eventName, defaultEventData = {}) => {
  if (!eventName) {
    throw new Error(
      `Expected useAnalytics("event.name") received event name: "${eventName}"`
    );
  }
  return (eventData = {}) => {
    dispatchAnalyticsEvent(eventName, { ...defaultEventData, ...eventData });
  };
};

export const useImmediateAnalytics = (eventName, defaultEventData = {}) => {
  if (!eventName) {
    throw new Error(
      `Expected useAnalytics("event.name") received event name: "${eventName}"`
    );
  }
  return (eventData = {}) => {
    dispatchImmediateAnalyticsEvent(eventName, {
      ...defaultEventData,
      ...eventData,
    });
  };
};

export const usePageLoadReady = () => {
  const [lastUpdatedTimestamp] = useContext(Context);

  const firedRef = useRef(false);

  useEffect(() => {
    const readyInterval = setInterval(() => {
      if (analyticsReady() && lastUpdatedTimestamp && !firedRef.current) {
        firedRef.current = true;
        clearInterval(readyInterval);
        dispatchImmediateAnalyticsEvent('pageload');
      }
    }, 100);
  }, [lastUpdatedTimestamp]);
};

export const Provider = ({ children }) => {
  const [lastUpdatedTimestamp, setTimestamp] = useState();

  const updateTimestamp = () => setTimestamp(Number(new Date()));

  return (
    <Context.Provider value={[lastUpdatedTimestamp, updateTimestamp]}>
      {children}
    </Context.Provider>
  );
};

export const Analytics = (payload) => {
  const [, updateTimestamp] = useContext(Context);

  useEffect(() => {
    setJLData(payload);
    updateTimestamp();
    debug(`dispatching event "${ANALYTICS_JLDATA_UPDATED}"`);
    document.body.dispatchEvent(new CustomEvent(ANALYTICS_JLDATA_UPDATED));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);

  return null;
};
