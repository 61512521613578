import React from 'react';
import App from 'next/app';
import * as Analytics from '../src/components/analytics';
import ContentLayout from '../src/components/Layout/ContentLayout';
import '../src/scss/reset.scss';
import '../src/scss/_theme.scss';

function ContentApp({ Component, pageProps }) {
  return (
    <Analytics.Provider>
      <ContentLayout {...pageProps}>
        <Component {...pageProps} />
      </ContentLayout>
    </Analytics.Provider>
  );
}

ContentApp.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext);

  return { ...appProps };
};

export default ContentApp;
